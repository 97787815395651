import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

// eslint-disable-next-line import/no-unresolved
const LibraryDashboard = Loadable(lazy(() => import('views/Library/LibraryDashboard/LibraryDashboard')));
const LibraryCategoryType = Loadable(lazy(() => import('views/Library/LibraryCategoryType/index')));
const LibraryCategory = Loadable(lazy(() => import('views/Library/LibraryCategory/index')));
const LibrarySetting = Loadable(lazy(() => import('views/Library/Seeting/index')));
const LibraryIssue = Loadable(lazy(() => import('views/Library/IssuBook/index')));
const BookHistory = Loadable(lazy(() => import('views/Library/IssuBook/HistoryBook')));
// const LibraryIssue = Loadable(lazy(() => import('views/Library/Issu')));
const LibraryAddBooks = Loadable(lazy(() => import('views/Library/AddBook/index')));
const EditCategoryType = Loadable(lazy(() => import('views/Library/LibraryCategoryType/EditCategoryType')));

const roles = ['ROLE_ADMIN', 'ROLE_COLLEGE_ADMIN', 'ROLE_SUPER_ADMIN', 'ROLE_COLLEGE_SUB_ADMIN'];

const LibraryRoutes = {
    path: '/',
    element: (
        <AuthGuard roles={roles}>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/library/dashboard',
            element: <LibraryDashboard />
        },
        {
            path: '/library/Category',
            element: <LibraryCategory />
        },
        {
            path: '/library/Categorytype',
            element: <LibraryCategoryType />
        },
        {
            path: '/library/Setting',
            element: <LibrarySetting />
        },
        // {
        //     path: '/library/Issue',
        //     element: <LibraryIssue />
        // },
        {
            path: '/library/history',
            element: <BookHistory />
        },
        {
            path: '/library/AddBook',
            element: <LibraryAddBooks />
        },
        {
            path: '/library/issuebook',
            element: <LibraryIssue />
        },
        {
            path: '/library/editCategoryType/id',
            element: <LibraryAddBooks />
        }
    ]
};
export default LibraryRoutes;
