/* eslint-disable prettier/prettier */
import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

const BudgetList = Loadable(lazy(() => import('views/budgetAndExpense/budgetHead/index')));
const BudgetAllocationList = Loadable(lazy(() => import('views/budgetAndExpense/budgetAllocation/index')));
const ExpenseSetup = Loadable(lazy(() => import('views/budgetAndExpense/expenses/main')));
const BudgetExpenseSummary = Loadable(lazy(() => import('views/application/expense-report/index')));
const roles = ['ROLE_ADMIN', 'ROLE_COLLEGE_ADMIN', 'ROLE_SUPER_ADMIN', 'ROLE_COLLEGE_SUB_ADMIN'];

const BudgetAndExpenseRoute = {
    path: '/',
    element: (
        <AuthGuard roles={roles}>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/budget/list',
            element: <BudgetList />
        },
        {
            path: '/budget-allocation/list',
            element: <BudgetAllocationList />
        },
        {
            path: '/expenses/list',
            element: <ExpenseSetup />
        },
        {
            path: '/report/expense/summary',
            element: <BudgetExpenseSummary/>
        }
    ]
};
export default BudgetAndExpenseRoute;
