import React, { useEffect, useState } from 'react';
// import { View, Text, Image, StyleSheet } from '@react-pdf/renderer';
import { View, Text, Image, StyleSheet } from '@react-pdf/renderer';

import axios from 'axios';

//   import { Font } from '@react-pdf/renderer';
//   Font.register({
//     family: 'Lucida Console',
//     src: 'https://fonts.gstatic.com/s/lucidaconsole/v16/9Bt3J0wNX1CzSLjb30BbHwLUuEpTyoUstqEm5AMlJo4.woff',
//   });
const styles = StyleSheet.create({
    header1: {
        display: 'flex'
    },
    image: {
        width: '50%',
        height: 'auto'
    },
    headerdata: {
        left: '33%',
        top: '-22px',
        color: '#0058A5',
        // fontFamily: "Lucida Console",
        fontWeight: 'bold'
    },
    session: {
        left: '80%',
        top: '-118px',
        color: 'white',
        height: '100px',
        width: '100px',
        // fontFamily: "Lucida Console",
        fontWeight: 'bold'
    },
    secondheader: {
        top: '-70px',

        textAlign: 'center'
    },
    heading: {
        fontSize: '14px'
    },
    subname: {
        color: '#0058A5',
        paddingTop: '4px'
    },
    date: {
        left: '200px',
        top: '-38px',
        fontSize: '14px'
    },

    header: {
        height: '30px',
        backgroundColor: '#FFC700',
        color: '#005a9a',
        fontSize: '12px',
        padding: '7px',
        top: '-50px',
        width: '515px',
        left: '35px',
        fontWeight: 'bold'
        // padding: "5px",
        // textAlign:"center"
    },
    headerblank: {
        height: '25px',
        backgroundColor: 'white'

        // padding: "5px",
        // textAlign:"center"
    },
    parent: {
        height: '30px',
        backgroundColor: '#FFC700',
        color: '#005a9a',
        fontSize: '12px',
        padding: '7px',
        top: '15px',
        width: '515px',
        left: '35px',
        fontWeight: '30px'

        // textAlign:"center"
    },
    location: {
        height: '30px',
        backgroundColor: '#FFC700',
        color: '#005a9a',
        fontSize: '12px',
        padding: '7px',
        top: '65px',
        width: '515px',
        left: '35px',
        fontWeight: 'bold'
    },
    address: {
        height: '25px',
        backgroundColor: '#0058A5',
        color: '#FFC700',
        fontSize: '10px',
        paddingLeft: '20px',
        paddingTop: '5px',
        top: '220px'
        // textAlign:"center"
    },
    examdetails: {
        height: '25px',
        backgroundColor: '#0058A5',
        color: '#FFC700',
        fontSize: '10px',
        paddingLeft: '20px',
        paddingTop: '5px',
        top: '325px'
        // textAlign: "center"
    },
    programname: {
        fontSize: '10px',
        paddingLeft: '25px',
        paddingBottom: '10px',
        // border: "1px solid black",
        marginTop: '10px'
    },
    co: {
        fontSize: '10px',
        paddingLeft: '22px',
        // border: "1px solid black",
        marginTop: '410px'
    },
    applicantname: {
        fontSize: '8px',
        padding: '5px'
    },
    tableContainer: {
        position: 'absolute',
        top: 260,
        width: '100%',
        left: '40%',
        paddingLeft: '20px'
        // borderWidth: 1,
        // borderColor: "#000",
    },
    tableContainerparent: {
        position: 'absolute',
        top: 360,
        width: '100%',
        paddingLeft: '8%'
    },
    tableContainerlocation: {
        position: 'absolute',
        top: 440,
        width: '100%',
        paddingLeft: '8%'
    },

    table: {
        width: '100%'
    },
    tableHeaderCell: {
        backgroundColor: '#eee',
        color: '#000',
        fontSize: '12px',
        padding: '6px',
        display: 'flex',
        flexDirection: 'column'
    },
    tableCell: {
        fontSize: '10px',
        paddingLeft: '16px',
        paddingTop: '9px',
        // backgroundColor: "#fff",
        color: '#000'
    },
    tableCellFather: {
        fontSize: '10px',
        paddingTop: '9px',

        // backgroundColor: "#fff",
        color: '#000'
    },
    tableCellFathercontact: {
        fontSize: '10px',
        paddingTop: '6px',
        // left: '3px',

        // backgroundColor: "#fff",
        color: '#000'
    },
    tableCellmothercontact: {
        fontSize: '10px',
        paddingTop: '6px',
        left: '3px',

        // backgroundColor: "#fff",
        color: '#000'
    },
    tableCellexam: {
        fontSize: '10px',
        padding: '6px',
        marginLeft: '90px',
        marginTop: '-66px'
    },
    tableCellcollege: {
        fontSize: '10px',
        padding: '6px',
        marginLeft: '230px',
        marginTop: '-65px'
    },
    tableCellyr: {
        fontSize: '10px',
        padding: '6px',
        marginLeft: '320px',
        marginTop: '-65px'
    },
    tableCelldiv: {
        fontSize: '10px',
        padding: '6px',
        marginLeft: '410px',
        marginTop: '-65px'
    },
    tableCellagg: {
        fontSize: '10px',
        padding: '6px',
        marginLeft: '460px',
        marginTop: '-56px'
    },
    sidelist: {
        fontSize: '10px',

        marginLeft: '65%',
        marginTop: '-2%'
    },
    sidelistMother: {
        fontSize: '10px',

        marginLeft: '49%',
        marginTop: '-2%'
    },
    sidelistMothercon: {
        fontSize: '10px',

        marginLeft: '48.5%',
        marginTop: '-2%'
    },

    sidelisthead: {
        fontSize: '12px',
        fontWeight: '700',
        marginLeft: '85%',
        marginTop: '-2%'
    },
    sidelistamount: {
        // fontSize: "12px",
        // fontWeight:"700",
        marginLeft: '85%',
        marginTop: '-2%',
        fontSize: '10px'
    },
    sidelisttotal: {
        fontSize: '12px',

        marginLeft: '65%',
        marginTop: '-2%'
    },
    sign: {
        top: '50px'
    }
});

// eslint-disable-next-line no-empty-pattern, react/prop-types
const HallTicketHeader = (props) => {
    console.log(props, 'hallticketId');
    const [dataset, setdataset] = useState([]);
    // const [url, seturl] = useState('');
    useEffect(() => {
        axios
            // eslint-disable-next-line react/destructuring-assignment, react/prop-types
            .get(
                // eslint-disable-next-line react/destructuring-assignment
                `/v1/entrance-exam/tickets/get?examTicketId=${props.examTicketId}`,

                {
                    headers: {
                        Authorization: `Bearer ${window.localStorage.getItem('serviceToken')}`,
                        'Content-Type': 'application/json'
                        // 'Content-Type': 'blob'
                    }
                }
            )
            .then((response) => {
                console.log(response.data.data, 'pdfdata');

                setdataset([response.data.data]);
                // response.data.data.blob()
                // const urldata = URL.createObjectURL(response.data.data.applicationForm.studentPhoto);
                // console.log(response.data.data.applicationForm.studentPhoto instanceof Blob)
                // const reader = new FileReader();
                // reader.readAsDataURL(response.data.data.applicationForm.studentPhoto);
                // reader.onloadend = () => {
                //     seturl(reader.response.data.data.applicationForm.studentPhoto);
                // };
                // seturl(urldata)
                localStorage.setItem('userId', response.data.data.userId);
            });
    }, []);
    console.log(dataset);

    return (
        <>
            <View style={styles.header1}>
                <Image src={`${process.env.PUBLIC_URL}/Registration form header.png`} />
                {/* <View style={styles.headerdata}>
          <Text style={{ padding: "5px" }}>University of Science &</Text>
          <Text style={{ padding: "5px" }}>Technology Meghalaya</Text>
        </View> */}

                <View style={styles.session}>
                    <Image src={`${process.env.PUBLIC_URL}/qr code.png`} />
                </View>
            </View>

            <View style={styles.secondheader}>
                {dataset.map((item) => (
                    <>
                        <View>
                            <Text style={styles.heading}>
                                Hall Ticket ( Academic Year :{' '}
                                {item.applicationForm.courseFollowUpDetails[0].courseDetail.academicYear.session.tag})
                            </Text>

                            <Text style={styles.subname}>{item.entranceExamMini.courseName}</Text>
                        </View>

                        <Text style={styles.date}>Date: {item.applicationForm.createdAt}</Text>
                    </>
                ))}
            </View>

            <View style={styles.header}>
                <Text style={{ paddingLeft: '5px' }}>Candidate Details</Text>
            </View>

            {/* <Image
                src={`${process.env.PUBLIC_URL}/qr code.png`}
                alt="ff"
                style={{ height: '100px', width: '100px', left: '10%', top: '-6%' }}
            /> */}

            {/* <Image src={`${process.env.PUBLIC_URL}/${url}`} style={{ height: '100px', width: '100px', left: '10%', top: '-6%' }} /> */}

            <View style={styles.tableContainer}>
                <View style={styles.table}>
                    {dataset.map((item) => (
                        <View>
                            <View>
                                <View style={styles.tableCell}>
                                    <Text>
                                        <Text>Candidate Name</Text> : {item.applicationForm.studentName}
                                    </Text>
                                </View>

                                <View style={styles.tableCell}>
                                    <Text>
                                        <Text>Candidate Application Number</Text> : {item.applicationForm.regNo}
                                    </Text>
                                </View>
                                <View style={styles.tableCell}>
                                    <Text>
                                        <Text>DOB</Text> : {item.applicationForm.personalDetails.dob}
                                    </Text>
                                </View>
                            </View>
                        </View>
                    ))}
                </View>
            </View>

            <View style={styles.parent}>
                <Text style={{ paddingLeft: '5px' }}>Exam Details</Text>
            </View>

            <View style={styles.tableContainerparent}>
                <View style={styles.table}>
                    {dataset.map((item) => (
                        <View>
                            <View>
                                <View style={styles.tableCellFather}>
                                    <Text>
                                        <Text>Exam Date</Text> : {item.entranceExamMini.examDate}
                                    </Text>
                                    <Text style={styles.sidelistMother}>
                                        <Text>Exam Time</Text> : {item.entranceExamMini.startTime}
                                    </Text>
                                </View>
                                <View style={styles.tableCellFathercontact}>
                                    <Text>
                                        <Text>Exam Duration</Text> : {item.entranceExamMini.examTime}
                                    </Text>
                                    <Text style={styles.sidelistMothercon}>
                                        <Text> Exam Type </Text>: {item.entranceExamMini.examMode}
                                    </Text>
                                </View>
                            </View>
                        </View>
                    ))}
                </View>
            </View>

            <View style={styles.location}>
                <Text style={{ paddingLeft: '5px' }}>Exam Location</Text>
            </View>

            <View style={styles.tableContainerlocation}>
                <View style={styles.table}>
                    {dataset.map((item) => (
                        <View>
                            <View>
                                <View style={styles.tableCellFather}>
                                    <Text>
                                        {' '}
                                        <Text>Exam Location</Text> : {item.entranceExamMini.examLocation}
                                    </Text>
                                </View>
                                <View style={styles.tableCellFathercontact}>
                                    <Text>
                                        <Text>Address</Text> : 3/2, AI Ameen Apartments, Pattalamma Road, Bangalore.
                                    </Text>
                                </View>
                            </View>
                        </View>
                    ))}
                    <View style={styles.sign}>
                        <Image
                            src={`${process.env.PUBLIC_URL}/sign.jpg`}
                            style={{
                                height: '35px',
                                width: '100px'
                            }}
                        />
                        <Text style={{ color: 'black', padding: '4px', fontSize: '10px' }}>
                            <Text> Signature</Text>
                        </Text>
                    </View>
                </View>
            </View>
        </>
    );
};

export default HallTicketHeader;
