/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
// material-ui
import { Button, Grid } from '@mui/material';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import SecondaryAction from 'ui-component/cards/CardSecondaryAction';
import { gridSpacing } from 'store/constant';
import { useEffect, useReducer, useState } from 'react';
// eslint-disable-next-line import/named
import { InstituteMobileContext } from './store/InstituteMobileContext';
import List from './List';
import { useNavigate, useParams } from 'react-router-dom';
import Add from './Add';
import axios from 'axios';

// ==============================|| TABLE - BASIC ||============================== //

export default function MobileMenu() {
    const navigate = useNavigate();
    const [iconList, setIconList] = useState([]);
    const [isAddDisplay, setAddDisplay] = useState(false);

    const handleAdd = () => {
        setAddDisplay(!isAddDisplay);
    };

    const instituteId = useParams().instituteId;

    return (
        <InstituteMobileContext>
            <Grid container spacing={gridSpacing}>
                <Grid item xs={12}>
                    <MainCard
                        content={false}
                        title={!isAddDisplay ? 'Mobile List' : 'Mobile Add'}
                        secondary={
                            !isAddDisplay ? (
                                <Button variant="contained" onClick={handleAdd}>
                                    Add +
                                </Button>
                            ) : (
                                <Button variant="contained" onClick={handleAdd}>
                                    Back
                                </Button>
                            )
                        }
                    >
                        {isAddDisplay ? (
                            <Add handleAdd={handleAdd} instituteId={instituteId} />
                        ) : (
                            <List handleAdd={setAddDisplay} instituteId={instituteId} />
                        )}
                    </MainCard>
                </Grid>
            </Grid>
        </InstituteMobileContext>
    );
}
