/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
// material-ui
import { useTheme } from '@mui/material/styles';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Stack, TextField } from '@mui/material';
import axios from 'axios';
import { openSnackbar } from 'store/slices/snackbar';
import { useFormik } from 'formik';
import { dispatch } from 'store';

// ===============================|| UI DIALOG - FORMS ||=============================== //

export default function UpdateJoinDateDialog(props) {
    console.log('props', props);
    const theme = useTheme();
    const formik = useFormik({
        initialValues: {
            joinDate: props.data.joinDate
        },
        // validationSchema,
        onSubmit: (value) => {
            console.log('value>>', value);
            const data = {};
            axios
                .put(`/v1/hostel/students/join-date/mark?studentId=${props.data.studentId}&joinDate=${value.joinDate}`, data, {
                    headers: {
                        Authorization: `Bearer ${window.localStorage.getItem('serviceToken')}`,
                        'Content-Type': 'application/json'
                    }
                })
                .then((result) => {
                    console.log(result, 'result');

                    dispatch(
                        openSnackbar({
                            open: true,
                            message: 'Join Date updated successfully',
                            variant: 'alert',
                            alert: {
                                color: 'success'
                            },
                            close: false
                        })
                    );
                    props.handleCloseD();
                    props.fetchStudentsDetails(props.fetchData);
                })
                .catch((error) => {
                    console.log(error, 'error');
                });
        }
    });

    return (
        <div>
            <Dialog open={props.open} onClose={props.handleCloseD} aria-labelledby="form-dialog-title">
                {props.open && (
                    <>
                        <DialogTitle id="form-dialog-title" minWidth={600}>
                            Update Join Date
                        </DialogTitle>
                        <DialogContent>
                            <form onSubmit={formik.handleSubmit}>
                                <Stack spacing={4}>
                                    <Grid item xs={12} md={4} sx={{ mt: 3 }}>
                                        <TextField
                                            type="date"
                                            id="joinDate"
                                            name="joinDate"
                                            value={formik.values.joinDate}
                                            label="Join Date*"
                                            onChange={(e) => {
                                                formik.handleChange(e);
                                            }}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            fullWidth
                                        />
                                    </Grid>
                                </Stack>

                                <Grid item sx={{ display: 'flex', justifyContent: 'end', mt: 3 }}>
                                    <Button variant="contained" onClick={props.handleCloseD} color="error">
                                        Cancel
                                    </Button>
                                    <Button type="submit" variant="contained" sx={{ ml: 4 }}>
                                        Update
                                    </Button>
                                </Grid>
                            </form>
                        </DialogContent>
                        <DialogActions />
                    </>
                )}
            </Dialog>
        </div>
    );
}
