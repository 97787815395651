/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { useContext, useEffect, useState } from 'react';
// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography
} from '@mui/material';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { openSnackbar } from 'store/slices/snackbar';
import { useFormik } from 'formik';
import { dispatch } from 'store';
import { gridSpacing } from 'store/constant';

// ===============================|| UI DIALOG - FORMS ||=============================== //

export default function DiscontinueHostel(props) {
    console.log('props', props);
    const [date, setDate] = useState('');
    const theme = useTheme();
    console.log(date, 'date');

    const discontinueAdmission = () => {
        axios
            .delete(`/v1/hostel/students/vacate?studentId=${props?.student?.studentId}&date=${date}`, {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem('serviceToken')}`,
                    'Content-Type': 'application/json'
                }
            })
            .then((result) => {
                console.log(result, 'result');

                dispatch(
                    openSnackbar({
                        open: true,
                        message: result.data.Msg,
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        },
                        close: false
                    })
                );
                props.handleClose();
                props.fetchStudentsDetails(props.fetchData);
            })
            .catch((error) => {
                console.log(error, 'error');
                dispatch(
                    openSnackbar({
                        open: true,
                        message: error.response.data.Msg,
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        },
                        close: false
                    })
                );
            });
    };
    const handleGetDiscontinue = (date) => {
        setDate(date);
    };

    return (
        <div>
            <Dialog open={props.open} onClose={props.handleClose} aria-labelledby="form-dialog-title">
                {props.open && (
                    <>
                        <DialogTitle id="form-dialog-title" minWidth={600}>
                            Are you sure you want to Discontinue Hostel for {props?.student?.studentName}
                        </DialogTitle>
                        <DialogContent>
                            <Stack spacing={4}>
                                <Grid container>
                                    <Grid item xs={12} md={6} sx={{ mt: 3 }}>
                                        <TextField
                                            type="date"
                                            id="joinDate"
                                            name="joinDate"
                                            required
                                            // value={formik.values.joinDate}
                                            label="Discontinue Date*"
                                            onChange={(e) => {
                                                handleGetDiscontinue(e.target.value);
                                            }}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                            </Stack>
                            <Grid sx={{ display: 'flex', justifyContent: 'end', mt: 2 }}>
                                <Button variant="contained" onClick={props.handleClose} color="error">
                                    Cancel
                                </Button>
                                <Button type="button" variant="contained" sx={{ ml: 4 }} onClick={discontinueAdmission}>
                                    Discontinue
                                </Button>
                            </Grid>
                        </DialogContent>
                        <DialogActions />
                    </>
                )}
            </Dialog>
        </div>
    );
}
