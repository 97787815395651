import React, { useContext, useEffect } from 'react';
import { MobileContext } from './store/InstituteMobileContext';
import EarningCard from 'ui-component/cards/Skeleton/EarningCard';
import { useTheme } from '@mui/material/styles';
import { DataGrid } from '@mui/x-data-grid';
import { Box } from '@mui/material';

const columns = [
    { field: 'slNo', headerName: '#', width: 80, renderCell: (index) => index.api.getRowIndex(index.row.id) + 1 },
    { field: 'name', headerName: 'Name', width: 250 },
    { field: 'seq', headerName: 'Sequence Number', width: 150 }
];

const List = () => {
    const [state, dispatchApp, updateDataHandler] = useContext(MobileContext);
    const theme = useTheme();

    return state.isLoading ? (
        <EarningCard />
    ) : (
        <Box
            sx={{
                height: 600,
                width: '100%',
                '& .MuiDataGrid-root': {
                    border: 'none',
                    '& .MuiDataGrid-cell': {
                        borderColor: theme.palette.mode === 'dark' ? theme.palette.text.primary + 15 : 'grey.200'
                    },
                    '& .MuiDataGrid-columnsContainer': {
                        color: theme.palette.mode === 'dark' ? 'grey.600' : 'grey.900',
                        borderColor: theme.palette.mode === 'dark' ? theme.palette.text.primary + 15 : 'grey.200'
                    },
                    '& .MuiDataGrid-columnSeparator': {
                        color: theme.palette.mode === 'dark' ? theme.palette.text.primary + 15 : 'grey.200'
                    }
                }
            }}
        >
            <DataGrid
                sx={{
                    mt: 2,
                    '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
                        borderRight: `1px solid ${theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'}`,
                        backgroundColor: 'f13223'
                    },
                    '& .MuiDataGrid-row:nth-child(even)': {
                        backgroundColor: '#f0f0f0' // Set your desired even cell background color
                    },
                    '& .MuiDataGrid-row:nth-child(odd)': {
                        backgroundColor: '#ffffff' // Set your desired odd cell background color
                    },
                    '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
                        borderBottom: `1px solid ${theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'}`
                        // backgroundColor: 'red !important'
                    },

                    // eslint-disable-next-line no-dupe-keys
                    '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
                        borderTop: `1px solid ${theme.palette.mode === 'light' ? '#f0f0f1' : '#313130'}`
                    }
                }}
                rows={state.payload}
                columns={columns}
                pageSize={20}
                rowsPerPageOptions={[20]}
            />
        </Box>
    );
};

export default List;
