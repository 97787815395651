/* eslint-disable no-use-before-define */
import React, { useContext, useState } from 'react';

import * as yup from 'yup';
import { MobileContext } from './store/InstituteMobileContext';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import MainCard from 'ui-component/cards/MainCard';
import { Alert, Button, Grid, TextField } from '@mui/material';
import { InputStyle } from 'assets/scss/InputStyle';
import { gridSpacing } from 'store/constant';
import axios from 'axios';

const validationSchema = yup.object({
    icon: yup.string().required('Icon is required.'),
    name: yup.string().required('Name is required.'),
    seq: yup.string().required('Sequence Number is required.')
});

const Add = ({ handleAdd, instituteId, setAddDisplay }) => {
    const [state, dispatchApp, updateDataHandler] = useContext(MobileContext);
    const [upload, setUpload] = useState(false);
    const dispatch = useDispatch();

    console.log({ updateDataHandler });

    const initialValues = {
        icon: '',
        name: '',
        seq: ''
    };

    const onSubmit = async (data) => {
        console.log({ data });
        const payload = [];
        payload.push(data);
        console.log(payload);

        const response = await axios.post(`/v1/institutes/icons/add?instituteId=${instituteId}`, payload, {
            headers: {
                'content-type': 'application/json',
                Authorization: `Bearer ${window.localStorage.getItem('serviceToken')}`
            }
        });
        resetForm();
        handleAdd();
        updateDataHandler();
    };

    const formik = useFormik({ initialValues, validationSchema, onSubmit });

    const { handleSubmit, setFieldValue, values, handleChange, resetForm } = formik;

    const loaderFunc = () => {
        setUpload(true);
    };

    console.log('add');
    return (
        <MainCard>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={gridSpacing}>
                    <Grid item xs={4}>
                        <TextField
                            fullWidth
                            id="name"
                            name="name"
                            label="name"
                            value={values.name}
                            onChange={handleChange}
                            error={formik.touched.name && Boolean(formik.errors.name)}
                            helperText={formik.touched.name && formik.errors.name}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            fullWidth
                            id="seq"
                            name="seq"
                            type="number"
                            label="Sequence Number"
                            value={values.seq}
                            onChange={handleChange}
                            error={formik.touched.seq && Boolean(formik.errors.seq)}
                            helperText={formik.touched.seq && formik.errors.seq}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="icon"
                            inputProps={{ accept: 'image/*' }}
                            name="icon"
                            required
                            sx={[InputStyle]}
                            onChange={(event) => {
                                loaderFunc();
                                const formData = new FormData();
                                formData.append('file', event.target.files[0]);
                                formData.append('fileName', event.target.files[0].name);
                                formData.append('instituteId', instituteId);
                                formData.append('folder ', 'ICONS');
                                // formData.append('academicYearId', studentData.academicYearId);
                                const config = {
                                    headers: {
                                        'content-type': 'multipart/form-data',
                                        Authorization: `Bearer ${window.localStorage.getItem('serviceToken')}`
                                    }
                                };
                                axios.post('/v1/utils/fileUpload/pre/signed/generic/institute', formData, config).then((response) => {
                                    setFieldValue('icon', response.data.data.path);
                                    setUpload(false);
                                });

                                console.log({ values });
                            }}
                            type="file"
                        />
                    </Grid>
                    <Grid md={12} sx={{ display: 'flex', justifyContent: 'end' }}>
                        <Button variant="contained" type="submit">
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </MainCard>
    );
};

export default Add;
