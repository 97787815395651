export const InputStyle = {
    '& fieldset.MuiOutlinedInput-notchedOutline': {
        borderColor: '#005a9a',
        borderWidth: '1.6px'
    },
    '.css-kykot2-MuiInputBase-root-MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
        borderColor: '#005a9a',
        borderWidth: '1.6px'
    }
};
