import axios from 'axios';
import { createContext, useEffect, useReducer } from 'react';
import { QualificationReducer } from './QualificationReducer';

export const QualificationContext = createContext();
export const QualificationContextProvider = (props) => {
    const [state, dispatchApp] = useReducer(QualificationReducer, {
        payload: [],
        reload: false,
        isLoading: true,
        data: {},
        success: false
    });
    // eslint-disable-next-line no-restricted-globals
    useEffect(() => {
        // eslint-disable-next-line no-use-before-define
        updateDataHandler();
    }, []);
    const updateDataHandler = () => {
        axios
            .get(`/v1/course-details/qualifications/list?collegeId=${localStorage.getItem('collegeId')}`, {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem('serviceToken')}`,
                    'Content-Type': 'application/json'
                }
            })
            .then((result) => {
                dispatchApp({
                    type: 'GET_DATA',
                    payload: result.data.qualificationTypeList,
                    reload: false,
                    isLoading: false,
                    data: {},
                    success: false
                });
            });
    };
    // eslint-disable-next-line react/destructuring-assignment, react/prop-types
    return <QualificationContext.Provider value={[state, dispatchApp, updateDataHandler]}>{props.children}</QualificationContext.Provider>;
};
