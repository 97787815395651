/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Grid, Menu, Typography, MenuItem, Button } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useNavigate } from 'react-router-dom';

const RoomCard = ({ floorData }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClose = () => setAnchorEl(null);

    const navigate = useNavigate();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        console.log(floorData);
    };

    const handleAllocateStudent = () => {
        navigate(`/hostel/student/allocation/${floorData.room.buildingId}/${floorData.room.floor}`);
    };

    const handleReAllocateStudent = (e) => {
        console.log(e.target.value);
    };

    const handleViewStudentDetails = (event) => {
        console.log(event.target);
        const room = floorData.room;
        const buildingId = room.buildingId;
        const floorNo = room.floor;
        const roomId = room.id;
        navigate(`/hostel/student/details/${buildingId}/${floorNo}/${roomId}`);
    };

    return (
        <Grid
            sx={{
                display: 'flex',
                flexDirection: 'column',
                borderRadius: '0%',
                backgroundColor: '#f5f5f5',
                width: '100%',
                height: '100%',
                m: 1
            }}
        >
            <Grid item md={12} sx={{ gap: 2, m: 1 }}>
                <Grid item md={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography variant="h5" sx={{ color: '#005e9f', p: 1 }}>
                        Room NO: {floorData?.room.name}
                    </Typography>
                    <div>
                        <Button
                            id="demo-positioned-button"
                            aria-controls={open ? 'demo-positioned-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            onClick={handleClick}
                            sx={{ p: 0, color: 'black' }}
                        >
                            <MoreHorizIcon />
                        </Button>
                        <Menu
                            id="demo-positioned-menu"
                            aria-labelledby="demo-positioned-button"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'left'
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left'
                            }}
                        >
                            <MenuItem onClick={handleAllocateStudent}>Allocate Student</MenuItem>
                            <MenuItem onClick={(e) => handleReAllocateStudent(e)}>Re-allocate Student</MenuItem>
                            <MenuItem onClick={(e) => handleViewStudentDetails(e)}>View Student Details</MenuItem>
                        </Menu>
                    </div>
                </Grid>
                <Typography variant="h5" sx={{ color: 'black', pl: 1, pr: 1 }}>
                    {floorData?.room.hostelRoomCategory.name}
                </Typography>
            </Grid>
            <Grid item sx={{ display: 'flex', flexDirection: 'row' }} md={12}>
                <Grid sx={{ backgroundColor: '#0051ad', p: 2, m: 2, borderRadius: '3%' }} md={6}>
                    <Typography variant="h5" sx={{ color: '#ffff' }}>
                        Total Beds:
                    </Typography>
                    <Typography variant="h3" sx={{ color: '#ffff' }}>
                        {floorData?.room.hostelRoomCategory.sharing}
                    </Typography>
                </Grid>
                <Grid
                    sx={{
                        backgroundColor:
                            floorData?.availableBeds === 0
                                ? '#ff4c48'
                                : floorData?.availableBeds === floorData?.room.hostelRoomCategory.sharing
                                ? '#76e05d'
                                : '#ffd000',
                        p: 2,
                        m: 2,
                        borderRadius: '3%'
                    }}
                    md={6}
                >
                    <Typography variant="h5" sx={{ color: '#ffff' }}>
                        Available Beds :
                    </Typography>
                    <Typography variant="h3" sx={{ color: '#ffff' }}>
                        {floorData?.availableBeds}
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default RoomCard;
