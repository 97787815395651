import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

const StudentAc = Loadable(lazy(() => import('views/application/student-accounts/StudentAccounts')));
const ChangeProgram = Loadable(lazy(() => import('views/application/student-accounts/ChangeCourse')));
const ProgramTransferHistory = Loadable(lazy(() => import('views/application/student-accounts/CourseTransferHistory')));
const EmployeeAc = Loadable(lazy(() => import('views/application/employee-accounts/EmployeeAccounts')));
const roles = ['ROLE_ADMIN', 'ROLE_COLLEGE_ADMIN', 'ROLE_SUPER_ADMIN', 'ROLE_COLLEGE_SUB_ADMIN'];
const AssignGroup = Loadable(lazy(() => import('views/application/employee-accounts/AssignGroup')));
const AdmissionCancelApprovalList = Loadable(lazy(() => import('views/application/student-accounts/AdmissionCancelApproovalLIst')));
const AdmissionCancelApproval = Loadable(lazy(() => import('views/application/student-accounts/AdmissionCancelApprooval')));
const AdmissionCancelApprovedList = Loadable(lazy(() => import('views/application/student-accounts/AdmissionCancelApprovedList')));

const ActiveAccounts = {
    path: '/',
    element: (
        <AuthGuard roles={roles}>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/student/accounts',
            element: <StudentAc />
        },
        {
            path: '/change/program',
            element: <ChangeProgram />
        },
        {
            path: '/program/transfer/history',
            element: <ProgramTransferHistory />
        },
        {
            path: '/employee/accounts',
            element: <EmployeeAc />
        },
        {
            path: '/assign/group',
            element: <AssignGroup />
        },
        {
            path: '/admission/cancel/approval/list',
            element: <AdmissionCancelApprovalList />
        },
        {
            path: '/admission/cancel/approval/:rollNumber',
            element: <AdmissionCancelApproval />
        },
        {
            path: '/admission/cancel/approved/list',
            element: <AdmissionCancelApprovedList />
        }
    ]
};
export default ActiveAccounts;
