/* eslint-disable no-const-assign */
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { Avatar, Box, Breadcrumbs, Divider, Grid, Menu, MenuItem, Typography } from '@mui/material';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import SkeletonEarningCard from 'ui-component/cards/Skeleton/EarningCard';

// assets

import { gridSpacing } from 'store/constant';
import axios from 'axios';
import application from 'menu-items/application';
import { useNavigate } from 'react-router-dom';
import DashboardSideImage from 'ui-component/cards/DashboardSideImage';

const CardWrapper = styled(MainCard)(({ theme }) => ({
    // backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.dark : theme.palette.secondary.dark,
    color: '#fff',
    overflow: 'hidden',
    position: 'relative',
    '&:after': {
        content: '""',
        position: 'absolute',
        width: 210,
        height: 210,
        // background:
        //     theme.palette.mode === 'dark'
        //         ? `linear-gradient(210.04deg, ${theme.palette.secondary.dark} -50.94%, rgba(144, 202, 249, 0) 95.49%)`
        //         : theme.palette.secondary[800],
        borderRadius: '50%',
        top: -85,
        right: -95,
        [theme.breakpoints.down('sm')]: {
            top: -105,
            right: -140
        }
    },
    '&:before': {
        content: '""',
        position: 'absolute',
        width: 210,
        height: 210,
        // background:
        //     theme.palette.mode === 'dark'
        //         ? `linear-gradient(140.9deg, ${theme.palette.secondary.dark} -14.02%, rgba(144, 202, 249, 0) 85.50%)`
        //         : theme.palette.secondary[800],
        borderRadius: '50%',
        top: -125,
        right: -15,
        opacity: 0.5,
        [theme.breakpoints.down('sm')]: {
            top: -155,
            right: -70
        }
    }
}));

// ===========================|| DASHBOARD DEFAULT - EARNING CARD ||=========================== //

const CountCard = ({ isLoading, totalData }) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const [studentCount, setStudentCount] = useState(0);
    const [studentAttendanceCount, setStudentAttendanceCount] = useState(0);
    const [leaverequest, setLeaverequest] = useState(0);
    // const [staffCount, setstaffCount] = useState(0);
    // const [collegeCount, setcollegeCount] = useState(0);
    // const [applicationCount, setapplicationCount] = useState(0);
    // const [admissionsNo, setadmissionsNo] = useState(0);
    // const [mystudentcount, setmystudentcount] = useState(0);

    useEffect(() => {
        axios
            .get(`/v1/staffs/teacher/dashboard`, {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem('serviceToken')}`,
                    'Content-Type': 'application/json'
                }
            })
            .then((result) => {
                console.log('result>>>', result);
                setStudentCount(result.data.data.studentCount);
                setStudentAttendanceCount(result.data.data.studentAttendance);
                setLeaverequest(result.data.data.leaverequest);
            });
        // axios
        //     .get(`/v1/staffs/students/list`, {
        //         headers: {
        //             Authorization: `Bearer ${window.localStorage.getItem('serviceToken')}`,
        //             'Content-Type': 'application/json'
        //         }
        //     })

        //     .then((response) => {
        //         // console.log('booklist',response?.data?.announcements);
        //         // setmystudentcount(response.data.students.length);
        //     });
    }, []);

    // studentsNo: 0, collegesNo: 2, staffsNo: 1, applicationsNo: 1, admissionsNo: 0
    return (
        <>
            {isLoading ? (
                <SkeletonEarningCard />
            ) : (
                <Grid
                    container
                    spacing={gridSpacing}
                    item
                    xs={12}
                    lg={12}
                    md={12}
                    sm={12}
                    style={{
                        borderRadius: '5%'
                    }}
                >
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <DashboardSideImage
                            primary={studentCount}
                            secondary="My "
                            secondarySub="Students"
                            color="#015ca1"
                            navigateUrl="/student/accounts"
                            style={{ cursor: 'pointer' }}
                            // navigateUrl="/registation/dashboard"
                            bgImage={<img src="/assets/images/icons/Registration.svg" alt="search" />}
                        />
                    </Grid>
                    {/* <Grid item lg={6} md={6} sm={12} xs={12}>
                        <DashboardSideImage
                            primary={staffCount}
                            secondary="Total "
                            secondarySub="Staff"
                            color="#015ca1"
                            // navigateUrl="/teacher/studentlist"
                            style={{ cursor: 'pointer' }}
                            // navigateUrl="/registation/dashboard"
                            bgImage={<img src="/assets/images/icons/Registration.svg" alt="search" />}
                        />
                    </Grid> */}
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <DashboardSideImage
                            primary={studentAttendanceCount}
                            secondary="Student"
                            secondarySub="Attendance"
                            style={{ cursor: 'pointer' }}
                            navigateUrl="/student/attendance"
                            color="#015ca1"
                            bgImage={<img src="/assets/images/icons/student.svg" alt="markattendance" />}
                        />
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <DashboardSideImage
                            primary={totalData}
                            secondary="Remaining"
                            secondarySub="Leaves"
                            style={{ cursor: 'pointer' }}
                            navigateUrl="/leavelist/bytype"
                            color="#015ca1"
                            bgImage={<img src="/assets/images/icons/student.svg" alt="markattendance" />}
                        />
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <DashboardSideImage
                            // primary={leaverequest}
                            secondary="Total"
                            secondarySub="Leave Requests"
                            style={{ cursor: 'pointer' }}
                            navigateUrl="/teacher/leaves/approve"
                            color="#015ca1"
                            bgImage={<img src="/assets/images/icons/student.svg" alt="markattendance" />}
                        />
                    </Grid>
                    {/* <Grid item lg={4} md={4} sm={12} xs={12}>
                        <DashboardSideImage
                            primary="100"
                            secondary="Assignment"
                            secondarySub="Upload"
                            color="#015ca1"
                            // navigateUrl="/registation/dashboard"
                            bgImage={<img src="/assets/images/icons/Registration.svg" alt="assignments" />}
                        />
                    </Grid> */}
                    {/* <Grid item lg={3} md={3} sm={12} xs={12}>
                        <DashboardSideImage
                            primary="100"
                            secondary="My"
                            secondarySub="Studend"
                            color="#015ca1"
                            // navigateUrl="/registation/dashboard"
                            bgImage={<img src="/assets/images/icons/Registration.svg" alt="Registration" />}
                        />
                    </Grid> */}

                    {/* <Grid item lg={4} md={4} sm={12} xs={12}>
                        <DashboardSideImage
                            primary={admissionsNo}
                            secondary="New"
                            secondarySub="Admission"
                            navigateUrl="/registation/dashboard"
                            color="#015ca1"
                            bgImage={<img src="/assets/images/icons/Admission.svg" alt="Admission" />}
                        />
                    </Grid> */}
                    {/* <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 2 }}>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Typography variant="h4">Fees Information</Typography>
                        </Breadcrumbs>
                    </Grid> */}
                    {/* <Grid item lg={4} md={4} sm={12} xs={12}>
                        <DashboardSideImage
                            primary={totalFeeCollected.toLocaleString('hi-IN')}
                            secondary="Total"
                            secondarySub="Fees Collected"
                            navigateUrl="/fees/dashboard"
                            color="#015ca1"
                            bgImage={<img src="/assets/images/icons//Total_Fee_Collected.svg" alt="Total_Fee_Collected" />}
                        />
                    </Grid> */}
                    {/* <Grid item lg={4} md={4} sm={12} xs={12}>
                        <DashboardSideImage
                            primary={0}
                            secondary="Total"
                            secondarySub="Fees Due"
                            // navigateUrl="/registation/dashboard"
                            color="#015ca1"
                            bgImage={<img src="/assets/images/icons/Total_Fee_Due.svg" alt="Admission" />}
                        />
                    </Grid> */}
                    {/* <Grid item lg={4} md={4} sm={12} xs={12}>
                        <DashboardSideImage
                            primary={studentCount}
                            secondary="Total"
                            secondarySub="Fine"
                            navigateUrl="/registation/dashboard"
                            color="#015ca1"
                            bgImage={<img src="/assets/images/icons/Total_Fee_Due.svg" alt="Admission" />}
                        />
                    </Grid> */}
                    {/* <Grid item lg={12} md={12} sm={12} xs={12}>
                        <CardWrapper border={false} content={false} sx={{ py: 2 }}>
                            <Breadcrumbs aria-label="breadcrumb">
                                <Typography sx={{ ml: 3 }} variant="h4">
                                    University Details
                                </Typography>
                            </Breadcrumbs>
                        </CardWrapper>
                    </Grid> */}
                    {/* <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 2 }}>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Typography variant="h4">University Information</Typography>
                        </Breadcrumbs>
                    </Grid> */}
                    {/* <Grid item lg={4} md={4} sm={12} xs={12}>
                        <DashboardSideImage
                            primary={collegeCount}
                            secondary="Total"
                            secondarySub="College"
                            navigateUrl="/college/list"
                            color="#015ca1"
                            bgImage={<img src="/assets/images/icons/Total_college.svg" alt="Total_Colleges" />}
                        />
                    </Grid> */}
                    {/* <Grid item lg={4} md={4} sm={12} xs={12}>
                        <DashboardSideImage
                            primary={staffCount}
                            secondary="Total"
                            secondarySub="Staff"
                            navigateUrl="/staff/registration"
                            color="#015ca1"
                            bgImage={<img src="/assets/images/icons/Total_Staff.svg" alt="Total_Staff" />}
                        />
                    </Grid> */}
                </Grid>
            )}
        </>
    );
};

CountCard.propTypes = {
    isLoading: PropTypes.bool
};

export default CountCard;
