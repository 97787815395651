import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import MainCard from 'ui-component/cards/MainCard';
import axios from 'axios';
import TableComponent from './TableComponent';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';

let buidingColumns;
let floorColumns;
let roomColumns;
let studentColumns;

const titleArray = ['Building List', 'Building', 'Floor', 'Room'];

const HostelReports = () => {
    const location = useLocation();
    console.log(location, 'location');
    if (location.pathname === '/hostel/dashboard') {
        buidingColumns = [
            {
                field: 'id',
                headerName: '#',
                width: 80,
                renderCell: (params) => {
                    if (params.row.buildingName !== 'Total') {
                        return params.api.getRowIndex(params.row.id) + 1;
                    }
                    return '';
                }
            },
            {
                field: 'buildingName',
                headerName: 'Building Name',
                width: 230,
                renderCell: (params) => {
                    const isTotal = params.row.buildingName === 'Total';
                    return <span style={{ color: isTotal ? '#005e9f' : '', fontWeight: 'bold' }}>{params.row.buildingName}</span>;
                }
            },
            { field: 'capacity', headerName: 'Capacity', width: 150 },
            { field: 'occupied', headerName: 'Occupied', width: 150, valueGetter: (p) => p.row.capacity - p.row.vacancy },
            { field: 'vacancy', headerName: 'Vacant', width: 150 }
        ];
        floorColumns = [
            {
                field: 'floor',
                headerName: 'Floor Number',
                width: 250,
                valueGetter: ({ row }) => (row.floor === 1 ? 'Ground Floor' : `${row.floor - 1} Floor`)
            },
            { field: 'capacity', headerName: 'Capacity', width: 170 },
            { field: 'occupied', headerName: 'Occupied', width: 170, valueGetter: (p) => p.row.capacity - p.row.vacancy },
            { field: 'vacancy', headerName: 'Vacant', width: 170 }
        ];
        roomColumns = [
            { field: 'id', headerName: '#', width: 80, renderCell: (index) => index.api.getRowIndex(index.row.id) + 1 },
            { field: 'roomName', headerName: 'Room Name', width: 150 },
            { field: 'capacity', headerName: 'Capacity', width: 180 },
            { field: 'occupied', headerName: 'Occupied', width: 180, valueGetter: (p) => p.row.capacity - p.row.vacancy },
            { field: 'vacancy', headerName: 'Vacant', width: 180, valueGetter: (p) => p.row.vacancy }
        ];
        studentColumns = [
            { field: 'id', headerName: '#', width: 80, renderCell: (index) => index.api.getRowIndex(index.row.id) + 1 },
            { field: 'studentName', headerName: 'Student Name', width: 170, valueGetter: ({ row }) => row.studentMini.studentName },
            { field: 'courseType', headerName: 'Course Type', width: 170, valueGetter: ({ row }) => row.studentMini.courseType },
            { field: 'rollNo', headerName: 'Roll Number', width: 170, valueGetter: ({ row }) => row.studentMini.rollNo },
            { field: 'enrollmentNo', headerName: 'Enrollment Number', width: 170, valueGetter: ({ row }) => row.studentMini.enrollmentNo }
        ];
    } else if (location.pathname === '/hostel/reports') {
        buidingColumns = [
            {
                field: 'id',
                headerName: '#',
                width: 80,
                renderCell: (params) => {
                    if (params.row.buildingName !== 'Total') {
                        return params.api.getRowIndex(params.row.id) + 1;
                    }
                    return '';
                }
            },
            {
                field: 'buildingName',
                headerName: 'Building Name',
                width: 230,
                renderCell: (params) => {
                    const isTotal = params.row.buildingName === 'Total';
                    return <span style={{ color: isTotal ? '#005e9f' : '', fontWeight: 'bold' }}>{params.row.buildingName}</span>;
                }
            },
            { field: 'capacity', headerName: 'Capacity', width: 150 },
            { field: 'occupied', headerName: 'Occupied', width: 150, valueGetter: (p) => p.row.capacity - p.row.vacancy },
            { field: 'vacancy', headerName: 'Vacant', width: 150 }
        ];
        floorColumns = [
            {
                field: 'floor',
                headerName: 'Floor Number',
                width: 300,
                valueGetter: ({ row }) => (row.floor === 1 ? 'Ground Floor' : `${row.floor - 1} Floor`)
            },
            { field: 'capacity', headerName: 'Capacity', width: 320 },
            { field: 'occupied', headerName: 'Occupied', width: 240, valueGetter: (p) => p.row.capacity - p.row.vacancy },
            { field: 'vacancy', headerName: 'Vacant', width: 240 }
        ];
        roomColumns = [
            { field: 'id', headerName: '#', width: 80, renderCell: (index) => index.api.getRowIndex(index.row.id) + 1 },
            { field: 'roomName', headerName: 'Room Name', width: 300 },
            { field: 'capacity', headerName: 'Capacity', width: 230 },
            { field: 'occupied', headerName: 'Occupied', width: 230, valueGetter: (p) => p.row.capacity - p.row.vacancy },
            { field: 'vacancy', headerName: 'Vacant', width: 230, valueGetter: (p) => p.row.vacancy }
        ];
        studentColumns = [
            { field: 'id', headerName: '#', width: 80, renderCell: (index) => index.api.getRowIndex(index.row.id) + 1 },
            { field: 'studentName', headerName: 'Student Name', width: 400, valueGetter: ({ row }) => row.studentMini.studentName },
            { field: 'courseType', headerName: 'Course Type', width: 200, valueGetter: ({ row }) => row.studentMini.courseType },
            { field: 'rollNo', headerName: 'Roll Number', width: 200, valueGetter: ({ row }) => row.studentMini.rollNo },
            { field: 'enrollmentNo', headerName: 'Enrollment Number', width: 200, valueGetter: ({ row }) => row.studentMini.enrollmentNo }
        ];
    }
    const [buildingRows, setBuildingRows] = useState([]);
    const [buildingId, setBuildingId] = useState('');
    const [floorList, setFloorList] = useState([]);
    const [roomList, setRoomList] = useState([]);
    const [studentList, setStudentList] = useState([]);
    const [navigationIndex, setNavigationIndex] = useState(0);

    const fetchAllBuildings = async () => {
        try {
            const response = await axios.get(`/v1/hostel/dashboard/buildings/report`, {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem('serviceToken')}`,
                    'Content-Type': 'application/json'
                }
            });

            const buildingData = response.data.buildingsReport;

            // Calculate the total values for each column
            const totalCapacity = buildingData.reduce((acc, building) => acc + building.capacity, 0);
            const totalOccupied = buildingData.reduce((acc, building) => acc + (building.capacity - building.vacancy), 0);
            const totalVacancy = buildingData.reduce((acc, building) => acc + building.vacancy, 0);

            // Create the "Total" row
            const totalRow = {
                id: 'total',
                buildingName: 'Total',
                capacity: totalCapacity,
                occupied: totalOccupied,
                vacancy: totalVacancy
            };

            // Add the "Total" row to your data
            const rowsWithTotal = [...buildingData, totalRow];

            setBuildingRows(rowsWithTotal);
        } catch (error) {
            console.error({ error });
        }
    };

    const handleCellClickBuidling = async (params, event) => {
        setBuildingId(params.row.id);
        try {
            const floorList = await axios.get(`/v1/hostel/dashboard/floors/report?buildingId=${params.row.id}`, {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem('serviceToken')}`,
                    'Content-Type': 'application/json'
                }
            });

            if (floorList.status === 200) {
                setFloorList(
                    floorList.data.floorsReport
                        .filter((obj, index) => obj.floor !== 0)
                        .map((obj) => ({
                            ...obj,
                            id: obj.floor
                        }))
                );
                setNavigationIndex(navigationIndex + 1);
            }
        } catch (error) {
            console.error({ error });
        }
    };

    const handleCellClickFloor = async (params, event) => {
        try {
            const roomList = await axios.get(`/v1/hostel/dashboard/rooms/report?buildingId=${buildingId}&floor=${params.row.floor}`, {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem('serviceToken')}`,
                    'Content-Type': 'application/json'
                }
            });
            setRoomList(roomList.data.roomsReport);
            setNavigationIndex(navigationIndex + 1);
        } catch (error) {
            console.error({ error });
        }
    };

    const handleCellClickRoom = async (params, event) => {
        try {
            const studentList = await axios.get(`/v1/hostel/rooms/students/list?roomId=${params.row.id}`, {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem('serviceToken')}`,
                    'Content-Type': 'application/json'
                }
            });
            const sortedRollNumbers = _.sortBy(studentList.data.hostelStudents, (rollNumber) => {
                const numericPart = parseInt(rollNumber.studentMini.rollNo.split('/').pop(), 10);
                return numericPart;
            });
            setStudentList(sortedRollNumbers);

            setNavigationIndex(navigationIndex + 1);
        } catch (error) {
            console.error({ error });
        }
    };

    const handleCellClickStudent = () => {};

    const handleNavigateBack = () => {
        if (navigationIndex !== 0) {
            setNavigationIndex(navigationIndex - 1);
        }
    };

    useEffect(() => {
        fetchAllBuildings();
    }, []);

    return (
        <MainCard>
            <Button onClick={handleNavigateBack}>{`${navigationIndex === 0 ? '' : '<'} ${titleArray[navigationIndex]}`}</Button>
            <div style={{ height: 500, width: '100%' }}>
                {navigationIndex === 0 && (
                    <TableComponent rows={buildingRows} columns={buidingColumns} handleRowClick={handleCellClickBuidling} />
                )}
                {navigationIndex === 1 && (
                    <TableComponent
                        rows={floorList}
                        columns={floorColumns}
                        handleRowClick={handleCellClickFloor}
                        getRowId={(row) => row.floor}
                    />
                )}
                {navigationIndex === 2 && <TableComponent rows={roomList} columns={roomColumns} handleRowClick={handleCellClickRoom} />}
                {navigationIndex === 3 && (
                    <TableComponent rows={studentList} columns={studentColumns} handleRowClick={handleCellClickStudent} />
                )}
            </div>
        </MainCard>
    );
};

export default HostelReports;
