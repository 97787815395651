import { lazy } from 'react';

// project imports

import Loadable from 'ui-component/Loadable';

import AdmissonHeader from 'views/Pdf/Admission/AdmissonHeader';
import HallTicketHeader from 'views/Pdf/HallTicket/HallTicketHeader';

// Department
const AdmissionPdf = Loadable(lazy(() => import('views/Pdf/Admission/AdmissionPdf')));
const HallTicketPdf = Loadable(lazy(() => import('views/Pdf/HallTicket/HallTicketPdf')));
const AdmissionContent = <AdmissonHeader />;
const HallTicketContent = <HallTicketHeader />;
const PdfRoutes = {
    path: '/',

    children: [
        {
            path: '/admission/pdf/:courseDetailId/:id',
            element: <AdmissionPdf AdmissionContent={AdmissionContent} />
        },
        {
            path: '/HallTicket/pdf/:id/:sId',
            element: <HallTicketPdf HallTicketContent={HallTicketContent} />
        }
    ]
};

export default PdfRoutes;
