import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

const OfflineFeePayment = Loadable(lazy(() => import('views/application/offlinefeepayment/OfflineFeePayment')));
const OfflineRegistrationFeePayment = Loadable(lazy(() => import('views/application/offlinefeepayment/OfflineRegistrationFees')));
const StudentOfflineFeePayment = Loadable(lazy(() => import('views/application/offlinefeepayment/StudentOfflineFeePayment')));
// const StudentOfflineFeePayment = Loadable(lazy(() => import('views/application/offlinefeepayment/TestStudentPayment')));
const UpdatePaymentCapture = Loadable(lazy(() => import('views/application/offlinefeepayment/UpdatePaymentCapture')));
const PaymentPlan = Loadable(lazy(() => import('views/application/partial_payment/PartialPaymentAndScholarShip')));
const ScholarshipApprovalList = Loadable(lazy(() => import('views/application/partial_payment/ScholarShipStudentList')));
const ScholarshipApproval = Loadable(lazy(() => import('views/application/partial_payment/ScholarshipApproval')));
const PartialPaymentApprovalList = Loadable(lazy(() => import('views/application/partial_payment/PartialPaymentStudentList')));
const PartialPaymentApproval = Loadable(lazy(() => import('views/application/partial_payment/PartialPaymentApproval')));
const StudentListOfflineFeePayment = Loadable(lazy(() => import('views/application/offlinefeepayment/StudentListOfflineFeePayment')));
const FeeRefund = Loadable(lazy(() => import('views/application/fee-refund')));
const PaymentReceipt = Loadable(lazy(() => import('views/application/offlinefeepayment/PaymentReceipt')));
const StudentFeesProfile = Loadable(lazy(() => import('views/application/offlinefeepayment/StudentFeesProfile')));
const SubjectApproval = Loadable(lazy(() => import('views/application/subject-approval/index')));
const PaymentsDashboard = Loadable(lazy(() => import('views/application/payments/Dashboard')));
const BacklogImprovement = Loadable(lazy(() => import('views/application/exam_fee_setup/backlogimprovementfeesetup/index')));
const BacklogImprovementEdit = Loadable(
    lazy(() => import('views/application/exam_fee_setup/backlogimprovementfeesetup/BackLogImprovementEdit'))
);
const FeeDefaulterHallTicket = Loadable(lazy(() => import('views/application/fee/fee_defaulter_hall_ticket/FeeDefaulterHallTicket')));
const HallTicketApprovalRequestList = Loadable(lazy(() => import('views/application/fee/fee_defaulter_hall_ticket/HallTicketRequestList')));
const roles = ['ROLE_ADMIN', 'ROLE_COLLEGE_ADMIN', 'ROLE_SUPER_ADMIN', 'ROLE_COLLEGE_SUB_ADMIN'];

const PaymentRoutes = {
    path: '/',
    element: (
        <AuthGuard roles={roles}>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/offline/fee/payment',
            element: <OfflineFeePayment />
        },
        {
            path: '/student/offline/fee/payment',
            element: <StudentOfflineFeePayment />
        },
        {
            path: '/offline/registration/fees/payment',
            element: <OfflineRegistrationFeePayment />
        },
        {
            path: '/student/offline/fee/payment/:roleNumber',
            element: <StudentOfflineFeePayment />
        },
        {
            path: '/student/list/offline/fee/payment',
            element: <StudentListOfflineFeePayment />
        },
        {
            path: '/update/payment/capture',
            element: <UpdatePaymentCapture />
        },
        {
            path: '/payment/plan',
            element: <PaymentPlan />
        },
        {
            path: '/scholarship/approval/list',
            element: <ScholarshipApprovalList />
        },
        {
            path: '/elective/subject/approval',
            element: <SubjectApproval />
        },
        {
            path: '/scholarship/approval/:id',
            element: <ScholarshipApproval />
        },
        {
            path: '/partial/pay/approval/list',
            element: <PartialPaymentApprovalList />
        },
        {
            path: '/partial/pay/approval/:id',
            element: <PartialPaymentApproval />
        },
        {
            path: '/fee/refund',
            element: <FeeRefund />
        },
        {
            path: '/payment/receipt',
            element: <PaymentReceipt />
        },
        {
            path: '/student/fees/profile',
            element: <StudentFeesProfile />
        },
        {
            path: '/payments/dashboard',
            element: <PaymentsDashboard />
        },
        {
            path: '/backlog/improvement/list',
            element: <BacklogImprovement />
        },
        {
            path: '/backlog/improvement/edit/:id',
            element: <BacklogImprovementEdit />
        },
        {
            path: '/fee/defaulter/hall/ticket',
            element: <FeeDefaulterHallTicket />
        },
        {
            path: '/hall/ticket/approval/request/list',
            element: <HallTicketApprovalRequestList />
        }
    ]
};
export default PaymentRoutes;
