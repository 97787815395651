/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import axios from 'axios';
import { createContext, useEffect, useReducer } from 'react';
import { instituteMobileReducer } from './instituteMobileReducer';
import { useParams } from 'react-router-dom';

export const MobileContext = createContext();
export const InstituteMobileContext = (props) => {
    const [state, dispatchMobileIcon] = useReducer(instituteMobileReducer, {
        payload: [],
        reload: false,
        isLoading: true,
        data: {},
        success: false
    });

    const instituteId = useParams().instituteId;

    const updateDataHandler = () => {
        axios
            .get(`/v1/institutes/icons/get?instituteId=${instituteId}`, {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem('serviceToken')}`,
                    'Content-Type': 'application/json'
                }
            })
            .then((result) => {
                dispatchMobileIcon({
                    type: 'GET_DATA',
                    isLoading: false,
                    payload: result.data.menuIcons,
                    data: {},
                    success: false
                });
            });
    };

    useEffect(() => {
        updateDataHandler();
    }, []);

    // eslint-disable-next-line react/destructuring-assignment, react/prop-types
    return <MobileContext.Provider value={[state, dispatchMobileIcon, updateDataHandler]}>{props?.children}</MobileContext.Provider>;
};
