import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// project imports
import useAuth from 'hooks/useAuth';

// ==============================|| AUTH GUARD ||============================== //

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const AuthGuard = ({ children, roles }) => {
    const auth = useAuth();
    const navigate = useNavigate();

    const { isLoggedIn } = useAuth();

    const currentUserRole = auth?.user?.role[0]?.roleName;

    useEffect(() => {
        if (!isLoggedIn) {
            navigate('login', { replace: true });
        }
    }, [isLoggedIn, navigate]);

    // useEffect(() => {
    //     console.log(currentUserRole, 'currentUserRole');
    //     if (!roles?.includes(currentUserRole)) {
    //         navigate('/pages/error');
    //     }
    // }, [navigate]);

    return children;
};

AuthGuard.propTypes = {
    children: PropTypes.node,
    roles: PropTypes.arrayOf(PropTypes.string)
};

export default AuthGuard;
