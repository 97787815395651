import { Link as RouterLink } from 'react-router-dom';

// material-ui
import { Link } from '@mui/material';

// project imports
import { DASHBOARD_PATH } from 'config';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => (
    <Link component={RouterLink} to={DASHBOARD_PATH}>
        <div style={{ padding: 2, backgroundColor: '#00326d' }}>
            <img src="/assets/images/icons/ustm-logo_T.svg" alt="t" height="50%" width="100%" />
        </div>
    </Link>
);

export default LogoSection;
