import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Card, Grid, Typography, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';

// =============================|| SIDE ICON CARD ||============================= //

const DashboardSideImage = ({ iconPrimary, primary, secondary, secondarySub, color, bgcolor, bgImage, navigateUrl }) => {
    const theme = useTheme();
    const matchDownXs = useMediaQuery(theme.breakpoints.down('sm'));
    const navigate = useNavigate();
    const IconPrimary = iconPrimary;
    const primaryIcon = iconPrimary !== undefined ? <IconPrimary /> : bgImage;
    const handleNavigation = () => {
        if (navigateUrl !== undefined) {
            navigate(navigateUrl);
        }
    };
    return (
        <Card
            sx={{
                bgcolor: bgcolor || '',
                position: 'relative',
                '&:hover': {
                    transition: 'transform 0.2s ease',
                    transform: 'scale(1.03)',
                    boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
                    borderRight: '10px solid',
                    borderColor: '#005a9a'
                }
            }}
        >
            <Grid container justifyContent="space-between" alignItems="center">
                <Grid item xs={3} sx={{ background: color, py: 3.5, px: 0 }}>
                    <Typography
                        variant="h5"
                        sx={{
                            textAlign: 'center',
                            color: '#fff',
                            '& > svg': {
                                width: 32,
                                height: 32
                            }
                        }}
                        align="center"
                    >
                        {primaryIcon}
                    </Typography>
                </Grid>
                <Grid item xs={9}>
                    <Grid
                        container
                        direction="column"
                        justifyContent="space-between"
                        spacing={1}
                        alignItems={matchDownXs ? 'center' : 'flex-start'}
                        onClick={handleNavigation}
                        sx={{ cursor: navigateUrl !== undefined ? 'pointer' : '' }}
                    >
                        <Grid item sm={12}>
                            <Typography variant="h2" sx={{ color: bgcolor ? '#fff' : '', ml: 2 }}>
                                {primary}
                            </Typography>
                        </Grid>
                        <Grid item sm={12}>
                            <Typography variant="body" align="left" sx={{ color: bgcolor ? '#fff' : 'grey.700', ml: 2 }}>
                                {secondary} <span style={{ color: '#015ca1', fontWeight: 'bold', fontSize: '15px' }}>{secondarySub}</span>{' '}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Card>
    );
};

DashboardSideImage.propTypes = {
    iconPrimary: PropTypes.object,
    primary: PropTypes.string,
    secondary: PropTypes.string,
    secondarySub: PropTypes.string,
    color: PropTypes.string,
    bgcolor: PropTypes.string
};

export default DashboardSideImage;
