/* eslint-disable react/prop-types */
import { Box, Hidden } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { useTheme } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';

const TableComponent = ({ columns, rows, handleRowClick }) => {
    const theme = useTheme();
    return (
        <DataGrid
            sx={{
                overflow: 'hidden',
                mt: 2,
                '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
                    borderRight: `1px solid ${theme.palette.mode === 'light' ? '#F0F0F0' : '#303030'}`,
                    backgroundColor: 'f13223'
                },
                '& .MuiDataGrid-row:nth-child(even)': {
                    backgroundColor: '#F0F0F0' // Set your desired even cell background color
                },
                '& .MuiDataGrid-row:nth-child(odd)': {
                    backgroundColor: '#FFFFFF' // Set your desired odd cell background color
                },
                '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
                    borderBottom: `1px solid ${theme.palette.mode === 'light' ? '#F0F0F0' : '#303030'}`
                    // backgroundColor: 'red !important'
                },
                // eslint-disable-next-line no-dupe-keys
                '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
                    borderTop: `1px solid ${theme.palette.mode === 'light' ? '#F0F0F1' : '#313130'}`
                },
                '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {
                    py: '10px'
                },
                '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
                    py: '20px'
                },
                '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {
                    py: '10px'
                }
            }}
            columns={columns}
            rows={rows}
            onCellClick={handleRowClick}
            getRowHeight={() => 'auto'}
            slots={{ toolbar: GridToolbar }}
        />
    );
};
export default TableComponent;
