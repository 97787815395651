/* eslint-disable no-fallthrough */
import axios from 'axios';

export const instituteMobileReducer = (state, action) => {
    switch (action.type) {
        case 'GET_DATA':
            console.log('this is GET DATA', action.payload);
            return { payload: action.payload };
        case 'Add':
            console.log(action.data);
            axios
                .post('/v1/institutes/icons/add', action.data, {
                    headers: {
                        Authorization: `Bearer ${window.localStorage.getItem('serviceToken')}`,
                        'Content-Type': 'application/json'
                    }
                })
                .then((result) => ({ ...state, success: false }));

        default:
            return state;
    }
};
